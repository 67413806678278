.ambigrams {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  padding-top: 5%;
}

.ambigrams-text {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: white;
}

.ambigrams-title {
  margin: 0.5em;
  align-self: center;
}

.ambigrams-description {
  align-self: flex-start;
  padding-left: 10%;
  padding-right: 10%;
  font-size: 1.25em;
}

.ambigrams-images-container {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5vh;
  max-width: 100em;
}

figure {
  color: white;
}

figcaption {
  font-size: 1em;
}

.back-arrow {
  position: absolute;
  top: 5%;
  left: 3%;
  width: 20px;
  height: 40px;
  border-radius: 16px;
  font-size: 2em;
  color: white;
}

.back-arrow:visited {
  color: white;
}

@media (max-width: 1200px) {
  .ambigrams-images-container {
    width: 80%;
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5em;
    /* margin-left: auto;
    margin-right: auto; */
  }

  .title {
    align-self: center;
  }
}

@media (max-width: 700px) {
  .ambigrams-images-container {
    width: 80%;
    gap: 3em;
  }
}
