.home {
  margin: 0;
  box-sizing: border-box;
  text-align: center;
  background-color: #00171e;
  display: "flex";
  min-height: 100vh;
  padding-left: 25vw;
  padding-right: 25vw;
  padding-top: 20vh;
  padding-bottom: 10vh;
}

@media (max-width: 1200px) {
  .home {
    padding-left: 20vw;
    padding-right: 20vw;
    padding-top: 10vh;
  }
}

@media (max-width: 700px) {
  .home {
    padding-left: 15vw;
    padding-right: 15vw;
    padding-top: 5vh;
  }
}

@media (min-width: 2400px) {
  .home {
    padding-left: 35vw;
    padding-right: 35vw;
    padding-top: 20vh;
  }
}

.home-container {
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.main-title {
  align-self: flex-start;
  display: inline-block;
  font-family: "Inter", "Segoe UI";
  font-size: calc(10px + 3vmin);
  font-weight: 500;
  background-image: linear-gradient(90deg, #1b5cfd, #bcdc49);
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.intro-container {
  text-align: start;
  color: white;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 24px;
  font-weight: 100;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3em;
  margin-bottom: 20vh;
}

.intro-text {
  line-height: 35px;
}
.button-row {
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
  gap: 16px;
}

.link-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  background-color: #9ed3ce;
  text-decoration: none;
}

.circle-icon {
  font-size: 1em;
  color: #00171e;
}
.icons {
  display: flex;
  gap: 16px;
}
.resume-button {
  align-items: center;
  background-color: #9ed3ce;
  border-radius: 33px;
  color: #00171e;
  display: inline-flex;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  min-height: 2em;
  font-weight: 600;
  justify-content: center;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  padding-left: 2em;
  padding-right: 2em;
  text-decoration: none;
  text-align: center;
}

.projects-header {
  color: white;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;
}

.projects-container {
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: 2em;
}

@media (max-width: 700px) {
  .intro-container {
    font-size: 20px;
    font-weight: 100;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-bottom: 10vh;
  }
  .button-row {
    flex-direction: column;
  }
}
