.image-container {
  width: 25%;
  max-height: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: flex-start;
  flex: 0 1 auto;
}

.image {
  width: 100%;
  height: 80%;
  min-height: 80%;
  max-height: 80%;
  border-radius: 4px;
  cursor: pointer;
  animation-fill-mode: forwards;
  opacity: 80%;
  margin-bottom: 0.5em;
  flex: 1 1 auto;
}

figcaption {
  width: 100%;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: white;
}

.jiggle:hover {
  animation: jiggle 0.2s forwards;
  animation-timing-function: linear;
}

.jiggle2:hover {
  animation: jiggle2 0.2s forwards;
  animation-timing-function: linear;
}

.rotate1 {
  animation: rotateOne 0.8s forwards;
  transition-property: transform 0.5s linear;
  animation-fill-mode: forwards;
}

.rotate2 {
  animation: rotateTwo 1s forwards;
  transition-property: transform 0.5s linear;
}

@keyframes rotateOne {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-180deg);
  }
}

@keyframes rotateTwo {
  from {
    transform: rotate(-180deg);
  }

  to {
    transform: rotate(-360deg);
  }
}

@keyframes jiggle {
  33% {
    transform: rotate(-5deg);
  }
  66% {
    transform: rotate(5deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes jiggle2 {
  from {
    transform: rotate(-180deg);
  }
  33% {
    transform: rotate(-185deg);
  }
  66% {
    transform: rotate(-175deg);
  }
  to {
    transform: rotate(-180deg);
  }
}

@media (max-width: 1200px) {
  .image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: auto;
    max-width: none;
    max-height: none;
  }

  .image {
    width: 100%;
    height: auto;
    max-height: none;
    max-width: none;
  }
}
