.card-container {
  align-items: flex-start;
  border-radius: 8px;
  display: flex;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 200;
  gap: 20px;
  padding: 20px;
  justify-content: flex-start;
  width: 100%;
  text-decoration: none;
  /* cursor: pointer; */
}

.card-container:hover {
  background-color: #0b395430;
}
.card-content {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: space-between; */
  text-align: start;
  gap: 8px;
}

.card-image {
  width: 15%;
  aspect-ratio: 1/1;
  border: 2px solid #bfd7ea;
  border-radius: 4px;
}

.card-title {
  font-weight: 600;
  gap: 0.5em;
  display: flex;
  align-items: center;
}
.card-pill {
  align-items: center;
  background-color: #1b5cfd;
  border-radius: 29px;
  color: #bcdc49;
  display: flex;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
  justify-content: center;
  padding-left: 0.8em;
  padding-right: 0.8em;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.pill-row {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  flex-wrap: wrap;
}

@media (max-width: 700px) {
  .card-container {
    flex-direction: column;
    width: 60vw;
  }
  .card-image {
    align-self: center;
    width: 50%;
  }
}
