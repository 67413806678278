.App {
  margin: 0;
  box-sizing: border-box;
  text-align: center;
  background-color: #00171e;
  display: "flex";
  min-height: 100vh;
  width: 100%;
  /* padding-left: 25vw;
  padding-right: 25vw;
  padding-top: 20vh;
  padding-bottom: 10vh; */
}
/* .App {
  margin: 0;
  box-sizing: border-box;
  text-align: center;
  background-color: #00171e;
  display: "flex";
  min-height: 100vh;
  padding-left: 25vw;
  padding-right: 25vw;
  padding-top: 20vh;
  padding-bottom: 10vh;
}

@media (max-width: 1200px) {
  .App {
    padding-left: 20vw;
    padding-right: 20vw;
    padding-top: 10vh;
  }
}

@media (max-width: 700px) {
  .App {
    padding-left: 15vw;
    padding-right: 15vw;
    padding-top: 5vh;
  }
}

@media (min-width: 2400px) {
  .App {
    padding-left: 35vw;
    padding-right: 35vw;
    padding-top: 20vh;
  }
} */
